// ** URL API FOR DEVELOP ONLY **

export const environment = {
  production: true,
  // apiURL: "https://web.chatfoto.id/",
  apiFilesURL: "https://dev-chatfoto-v2.dmmrnd.id/",
  apiFrame: "https://dmmrnd.id/dev-chatfoto/public/",
  urlIndex:'https://dev.chatfoto.id',
  apiURL: "https://dev-chatfoto-v2.dmmrnd.id/",
  apiURLWa: "https://api.chatfoto.id/whatsapp/",
  apiURLsicepat: "https://dmmspot.id/api_third_party/sicepatqa/request/",
  apiURLpayment: 'https://dmmspot.id/api-payment/public/',

  // apiUrl: "http://dev-chatfoto.dmmrnd.id/public/", //change test
  
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  firebase: {
    apiKey: "AIzaSyCadMIxT9RM3gWSTD5Xp80ufgloM-2P2oE",
    authDomain: "easy-photo-fujifilm.firebaseapp.com",
    projectId: "easy-photo-fujifilm",
    storageBucket: "easy-photo-fujifilm.appspot.com",
    messagingSenderId: "155464318501",
    appId: "1:155464318501:web:3a924ffd3f96b5f82709cc",
    measurementId: "G-F3711N0D71",
    vapidKey: "BDexmkmUJxKnjetCPln8kTiKw1_SAEjBZ_rFOVqINV_ja8kDhDGKLYjv0Pfc3eTQybU-NCDlMLE01C_nH0S-GUg"
  },
};
